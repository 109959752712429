import React, { useEffect, useState } from 'react';
import { apiService } from '../../../_services/api.service';
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import currencyDisplayHelper from '../../../_helpers/currencyDisplayHelper';
import { Bar } from 'react-chartjs-2';
import './style.css';

const HomeBalanceItem = ({ icon, color, value, type }) => (
    <div className="col-sm-3">
        <div className="home-balance-item non-decoration">
            <div className="col-4">
                <div className="hbi-icon-container" style={{ backgroundColor: color }}>
                    <i className={`${icon} hbi-icon`}></i>
                </div>
            </div>
            <div className="col-8 home-balance-item-content">
                <h5 className="hbi-text">{value}</h5>
                <p className="hbi-text">{type}</p>
            </div>

        </div>

    </div>
)

const MerchantAnalytics = ({ location, infosetting }) => {
    const [merchantId, setMerchantId] = useState(null);
    const [merchantData, setMerchantData] = useState(null);
    const [totalProducts, setTotalProducts] = useState("Loading...");
    const [chartData, setChartData] = useState({
        labels: [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ],
        datasets: [{
            label: "Completed",
            backgroundColor: `#7262E6`,
            borderWidth: 2,
        }]
    });

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        let route = "orders";
        if (query.get("filter")) {
            let filterQuery = JSON.parse(query.get("filter"));
            console.log({ filterQuery });
            setMerchantId(filterQuery.merchant ? filterQuery.merchant : null);
        }
    }, [location]);

    useEffect(() => {
        console.log({ merchantId });
        if (merchantId) {
            fetchData();
            fetchOrder();
        }
    }, [merchantId]);

    useEffect(() => {
        console.log({ merchantData });
    }, [merchantData]);

    const fetchOrder = () => {
        apiService.getAll(`orders/merchant-data?merchant=${merchantId}`).then(setMerchantData);
    }

    const fetchData = () => {
        apiService.filter("products", { query: { merchant: merchantId }, project: { _id: 1 } }).then(mp => {
            setTotalProducts(mp.length)
        });
        getCharData();
    }

    const [chartVisible, setCharVisible] = useState(false);

    const getCharData = async () => {
        let chartDataTemp = chartData;
        chartDataTemp.datasets = [];
        let res = null;

        res = await apiService.getAll(`orders/merchant-graph/Completed/netTotal?merchant=${merchantId}`);
        chartDataTemp.datasets.push({
            label: res.label,
            backgroundColor: `#63C2DE`,
            borderWidth: 2,
            data: res.data
        });

        setChartData(chartDataTemp);


        res = await apiService.getAll(`orders/merchant-graph/Completed/commission?merchant=${merchantId}`);
        chartDataTemp.datasets.push({
            label: "Commision",
            backgroundColor: `#00923F`,
            borderWidth: 2,
            data: res.data
        });

        res = await apiService.getAll(`orders/merchant-graph/Cancelled/netTotal?merchant=${merchantId}`);
        chartDataTemp.datasets.push({
            label: res.label,
            backgroundColor: `#F86C6B`,
            borderWidth: 2,
            data: res.data
        });

        setCharVisible(true);
    }
    return (
        <div className="animated fadeIn" >
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardHeader>
                            <i className="fa fa-first-order"></i> Merchant Analytics
                            </CardHeader>
                        <CardBody>

                            <div className="row mb-4">
                                {
                                    merchantData && <React.Fragment>
                                        <HomeBalanceItem icon="fas fa-archive"
                                            color="#2286DF" type="Products"
                                            value={totalProducts} />

                                        <HomeBalanceItem icon="fas fa-chart-line"
                                            color="#7262E6" type="Total Revenue"
                                            value={currencyDisplayHelper(infosetting, merchantData.netTotal)}
                                        />

                                        <HomeBalanceItem icon="fas fa-certificate"
                                            color="#FF4966" type="Total Earning"
                                            value={currencyDisplayHelper(infosetting, merchantData.merchantEarning)}
                                        />

                                        <HomeBalanceItem icon="fas fa-coins"
                                            color="#2286DF" type="Company Commission"
                                            value={currencyDisplayHelper(infosetting, merchantData.commission)}
                                        />
                                    </React.Fragment>
                                }
                            </div>


                            {chartVisible && <Bar
                                data={chartData}
                                options={{
                                    title: {
                                        display: true,
                                        text: 'Monthly Transactions',
                                        fontSize: 20
                                    },
                                    legend: {
                                        display: true,
                                        position: 'right'
                                    }
                                }}
                            />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default MerchantAnalytics;