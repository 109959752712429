const config = {
    baseUrl: 'https://dine.com.co',
    // baseUrl: '',
    // baseUrl: 'http://localhost:3000',
    googleMapAPIKey: "AIzaSyC7iyBNdfpniVJ2uSig7YV5z1qRInJKaVs",
    mapZoom: 15,
    defaultLatLng: {
        lat: 41.492224,
        lng: -81.785381
    }
}
export default config;