import React, { Component } from 'react';
import PaginationComponent from 'react-reactstrap-pagination';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import config from '../../../_config';
import currencyDisplayHelper from '../../../_helpers/currencyDisplayHelper';
import socketUpdater from '../../../_helpers/socketUpdater';
import { apiService } from '../../../_services/api.service';
import SearchField from '../../../_ui_components/SearchField';
import './style.css';

const MOrderRow = ({ co, index, infosetting, onDelete }) => {
    return (
        <div className="co-item row">

            <div className="co-field">
                <span className="co-field-label">OrderId</span>
                <span className="co-field-value">{co.orderId}</span>

                <span className="co-field-label">Customer</span>
                <span className="co-field-value">{`${co.reservationInfo.name}`}</span>


                <span className="co-field-label">Restaurant Name</span>
                <span className="co-field-value">{`${co.restaurantObj.name}`}</span>
            </div>

            <div className="co-field">
                <span className="co-field-label">Menu Items</span>
                <span className="co-field-value">{co.menuItems.length}</span>

                <span className="co-field-label">Net Total</span>
                <span className="co-field-value">{currencyDisplayHelper(infosetting, co.netTotal)}</span>

                <span className="co-field-label">Platform</span>
                <span className="co-field-value">{co.platform}</span>
            </div>


            <div className="co-field">
                <span className="co-field-label">Status</span>
                <span className="co-field-value">{co.status}</span>


                <span className="co-field-label">Payment Option</span>
                <span className="co-field-value">{co.paymentOptionObj.name}</span>

            </div>

            <div className="co-field" style={{ flex: 1.2 }}>
                <span className="co-field-label">Date</span>
                <span className="co-field-value">{new Date(co.dateTime).toLocaleString()}</span>

            </div>

            <div className="co-field-action">
                <Link className="btn btn-sm btn-info" to={`/order/${co.orderId}`}>
                    View
                </Link>

                <button className="btn btn-sm btn-danger" onClick={() => onDelete && onDelete({ _id: co._id, orderId: co.orderId })}>
                    Delete
                </button>

            </div>
        </div>
    )
}

class Orders extends Component {
    state = {
        mOrders: null,
        mFilterOrders: null,
        activePagination: {
            pageLoading: true,
            selectedPage: 1,
            pageSize: 5,
            maxPaginationNumbers: 9
        },
        loaded: false,
        search: ""
    }
    componentDidMount() {
        this.fetchOrders();
        socketUpdater("order", null, null, this.fetchOrders)

    }
    componentDidUpdate(prevProps, prevState) {
        const { merchant } = this.props;
        const { search, mOrders } = this.state;

        if (search !== prevState.search || mOrders !== prevState.mOrders) {
            if (search == "") {
                this.setState({ mFilterOrders: mOrders })
            } else {
                let searchQuery = search.toLowerCase()
                let fProd = mOrders.filter(m =>
                    m.orderId == searchQuery
                    || m.merchantObj.storeName.toLowerCase().includes(searchQuery)
                    || m.userObj.name.toLowerCase().includes(searchQuery)
                    || m.paymentOptionObj.name.toLowerCase().includes(searchQuery)
                    || new Date(m.dateTime).toLocaleString().toLowerCase().includes(searchQuery)
                    || m.billingInfo.firstName.toLowerCase().includes(searchQuery)
                    || m.status.toLowerCase().includes(searchQuery)
                );
                this.setState({ mFilterOrders: fProd })
            }
        }
    }
    fetchOrders = () => {
        const query = new URLSearchParams(this.props.location.search);
        let route = "orders";
        if (query.get("filter")) {
            let filterQuery = JSON.parse(JSON.stringify(query.get("filter")));
            apiService.filter(route, { query: JSON.parse(filterQuery), sortQuery: { dateTime: -1 } },).then(mOrders => {
                this.setState({ mOrders, loaded: true })
            });
        } else {
            apiService.getAll(route).then(mOrders => {
                this.setState({ mOrders, loaded: true })
            }).catch(e => {
                console.log({ e });
            })
        }
    }

    handleSelectedActivePage = selectedPage => {
        let activePagination = this.state.activePagination;
        activePagination.selectedPage = selectedPage;
        this.setState({ activePagination })
    };

    handleExport = () => {

        const query = new URLSearchParams(this.props.location.search);
        let filterQuery = null;
        if (query.get("filter")) {
            filterQuery = JSON.parse(JSON.stringify(query.get("filter")));
        }
        apiService.add(`orders/export/excel`, { query: JSON.parse(filterQuery), sortQuery: { dateTime: -1 } })
            .then(({ path }) => {
                var win = window.open(config.baseUrl + "/" + path, '_blank');
                win.focus();
            })
            .catch(e => {
                toast.error("An error occured while exporting!")
            })
    }
    handleDelete = ({ _id, orderId }) => {
        if (window.confirm(`Are you sure you want to delete #${orderId} order?`)) {
            apiService.delete('orders', _id)
        }

    }

    render() {

        const { search, mFilterOrders, loaded, activePagination } = this.state;
        const { infosetting } = this.props;
        return (<div className="animated fadeIn">
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardHeader>
                            <i className="fa fa-first-order"></i> Orders
                            <button
                                onClick={this.handleExport}
                                className="btn btn-primary btn-sm mr-2"
                                style={{ float: "right" }}
                            >
                                Export
                            </button>
                        </CardHeader>
                        <CardBody>
                            <SearchField
                                label="Orders"
                                value={search}
                                onChange={(v) => this.setState({ search: v })}
                            />

                            {
                                mFilterOrders && <div className="co-container">
                                    {loaded && mFilterOrders.length == 0 && <tr > <td colspan="12" className="text-center">No Orders found!</td> </tr>}

                                    {
                                        mFilterOrders.slice(
                                            (activePagination.selectedPage - 1) * activePagination.pageSize,
                                            activePagination.selectedPage * activePagination.pageSize
                                        ).map((co, i) => <MOrderRow co={co} onFetchProduct={this.onFetchProduct} index={i} key={i} infosetting={infosetting} onDelete={this.handleDelete} />)
                                    }
                                    <div className="pagination-container">
                                        <PaginationComponent
                                            totalItems={mFilterOrders.length}
                                            pageSize={activePagination.pageSize}
                                            onSelect={this.handleSelectedActivePage}
                                            maxPaginationNumbers={
                                                activePagination.maxPaginationNumbers
                                            }
                                            activePage={activePagination.selectedPage}
                                        />
                                    </div>

                                </div>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
        );
    }
};

function mapStateToProps(state) {
    const { authentication } = state;
    return authentication;
}


export default connect(mapStateToProps)(withRouter(Orders));