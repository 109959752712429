import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import config from '../../../_config';
import calculateCartTotal from '../../../_helpers/calculateCartTotal';
import currencyDisplayHelper from '../../../_helpers/currencyDisplayHelper';
import socketUpdater from '../../../_helpers/socketUpdater';
import { apiService } from '../../../_services/api.service';
import RatingSelector from '../../../_ui_components/RatingSelector';
import getImageURL from './../../../_helpers/imageHelper';
import AddProductModal from './AddProductModal';
import CancellationNotesModal from './CancellationNotesModal';
import DeliveryAddressEditModal from './DeliveryAddressEditModal';
import './style.css';

const StatusButton = ({ display, value, changingStatus, changeStatus, onClick }) => (
    <div className="o-status-option"
        onClick={() => {
            onClick && onClick(value);
            changeStatus && changeStatus(value)
        }}
    >{changingStatus === value ? "Please Wait" : display}</div>
)

const Order = ({ history, infosetting, match }) => {
    const pageId = match.params.id;
    const [order, setOrder] = useState(null);
    const [merchant, setMerchant] = useState(null);
    const [mapId, setmapId] = useState("");
    const [orderCart, setOrderCart] = useState(null);
    const [changingStatus, setChangingStatus] = useState(null);

    let unmounted = false;

    useEffect(() => () => {
        unmounted = true;
    }, []);

    useEffect(() => {
        if (pageId) {
            fetchOrder(pageId);

            socketUpdater('order', null, null, (action, data) => {
                if (!unmounted) {
                    if (action === "update" && data.orderId + "" === pageId) {
                        fetchOrder(pageId);
                    }
                }
            })
        }

    }, [pageId]);

    const fetchOrder = (pageId) => {
        apiService.getSingle("orders/fetch", pageId)
            .then(setOrder)
            .catch((e) => history.goBack())
    }
    useEffect(() => {
        if (order) {
            // apiService.getSingle("merchants", order.merchant).then(setMerchant);
            let mapID = `map-${order.orderId}`;
            setmapId(mapID);
            setOrderCart({
                menuItems: order.menuItems,
                subTotal: order.subTotal,
                serviceCharges: order.serviceCharges,
                discount: order.discount,
                coupan: order.coupan,
                commission: order.commission,
                commissionType: order.commissionType,
                merchantEarning: order.merchantEarning,
                netTotal: order.netTotal
            })
            setTimeout(() => {

                console.log(order.restaurantObj.addressLocation);
                let myLatLngVal = myLatLng(order.restaurantObj.addressLocation);
                const map = new window.google.maps.Map(
                    document.getElementById(mapID),
                    {
                        zoom: config.mapZoom,
                        center: myLatLngVal,
                        streetViewControl: false,
                        fullscreenControl: false,
                        zoomControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        mapTypeControl: false
                    }
                );
                var marker = new window.google.maps.Marker({
                    position: myLatLngVal,
                    title: "Delivery Location"
                });

                marker.setMap(map);
            }, 500);
        }
    }, [order]);

    const myLatLng = (location) => {
        return location && location.coordinates ?
            location.coordinates[0] < 0 ? { lat: location.coordinates[1], lng: location.coordinates[0] } : { lat: location.coordinates[0], lng: location.coordinates[1] } :
            { lat: config.defaultLatLng.lat, lng: config.defaultLatLng.lng };
    }

    const addToCart = (product) => {
        let cart = JSON.parse(JSON.stringify(orderCart))
        let productIndex = cart.menuItems.findIndex(s => s._id === product._id);
        if (productIndex !== -1) {
            cart.menuItems[productIndex]["cartQty"] += 1;
            cart.menuItems[productIndex]["cartItemPrice"] = cart.menuItems[productIndex]["price"] * cart.menuItems[productIndex]["cartQty"];
        } else {
            product["cartQty"] = 1;
            product["cartItemPrice"] = product["price"] * product["cartQty"];
            cart.menuItems.push(product);
        }

        cart = calculateCartTotal(cart, infosetting, merchant);
        setOrderCart(cart);
    }
    const addToWithAddonsCart = (product, updated = false) => {
        let cart = JSON.parse(JSON.stringify(orderCart))
        let productIndex = cart.menuItems.findIndex(s => s._id === product._id);
        if (productIndex !== -1 && updated) {
            cart.menuItems[productIndex]["cartQty"] += 1;
            cart.menuItems[productIndex]["cartItemPrice"] = cart.menuItems[productIndex]["price"] * cart.menuItems[productIndex]["cartQty"];
        } else {
            cart.menuItems.push(product);
        }
        cart = calculateCartTotal(cart, infosetting, merchant);
        setOrderCart(cart);
    }
    const removeFromCart = (product) => {
        let cart = JSON.parse(JSON.stringify(orderCart))
        let productIndex = cart.menuItems.findIndex(s => s._id === product._id);
        if (productIndex !== -1) {

            if (cart.menuItems[productIndex]["cartQty"] > 1) {
                cart.menuItems[productIndex]["cartQty"] -= 1;
                cart.menuItems[productIndex]["cartItemPrice"] = cart.menuItems[productIndex]["price"] * cart.menuItems[productIndex]["cartQty"];
            } else {
                if (cart.menuItems.length > 1) {
                    if (window.confirm(`Do you want to remove ${cart.menuItems[productIndex].productObj.name} from order?`)) {
                        cart.menuItems.splice(productIndex, 1);
                    }
                }
            }
            cart = calculateCartTotal(cart, infosetting, merchant);
            setOrderCart(cart);
        }


    }
    const changeStatus = (status) => {
        if (!changingStatus) {
            setChangingStatus(status);
            let tempOrder = JSON.parse(JSON.stringify(order));
            tempOrder["prevStatus"] = tempOrder.status;
            tempOrder.status = status;
            if (status == "Confirmed") {
                Object.keys(orderCart).forEach(ocKey => {
                    tempOrder[ocKey] = orderCart[ocKey]
                })
                if (order.netTotal !== orderCart.netTotal) {
                    tempOrder["netTotalChange"] = true;
                }
            }
            tempOrder["netTotalCurrency"] = currencyDisplayHelper(infosetting, order.netTotal);
            updateOrder(tempOrder);
        }
    }
    const handleDeliverySubmit = (deliveryInfo) => {
        setChangingStatus("");
        let tempOrder = JSON.parse(JSON.stringify(order));
        tempOrder["prevStatus"] = "DELIVERY_ADDRESS_CHANGE";
        tempOrder.deliveryInfo = deliveryInfo;
        updateOrder(tempOrder);
    }
    const updateOrder = (tempOrder) => {
        apiService.update('orders', tempOrder, tempOrder._id).then(() => {
            setChangingStatus(null);
        });
    }

    const handleCancellationNotesSubmit = (cancellationData) => {
        let status = "Cancelled";
        setChangingStatus(status);
        let tempOrder = JSON.parse(JSON.stringify(order));
        tempOrder.cancellationNotes = cancellationData.cancellationNotes;
        tempOrder["prevStatus"] = tempOrder.status;
        tempOrder.status = status;
        updateOrder(tempOrder);
    }
    return (
        <div className="animated fadeIn">
            {
                order && <Row>
                    <Col xl={12}>
                        <Card>
                            <CardHeader>
                                <div className="dashboard-page-header">
                                    <div className="dashboard-title-area">
                                        <i className="fab fa-first-order"></i>
                                        <span>Order #{order.orderId}</span>
                                        <span className="primary-text ml-1">({order.status})</span>  <br />
                                        <div className="d-flex flex-column">
                                            <span className="o-place-datetime">Placed On: {new Date(order.dateTime).toLocaleString()}</span>
                                        </div>
                                    </div>
                                    <div className="dashboard-button-area">
                                        <button className="btn btn-sm btn-primary" onClick={() => history.goBack()}>
                                            Back
                                        </button>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className="row">

                                    <div className="col-sm-8">
                                        {
                                            orderCart &&
                                            <div className="o-menuItems-container">
                                                {merchant && order.status == "Placed" &&
                                                    <div className="o-add-product-container">
                                                        <AddProductModal merchant={merchant._id} cart={orderCart} infosetting={infosetting}
                                                            addToCart={addToCart}
                                                            addToWithAddonsCart={addToWithAddonsCart}
                                                            removeFromCart={removeFromCart} />
                                                        <button className="btn btn-sm btn-primary" data-toggle="modal" data-target={`#addProductModal`} id="addProductModalOpener">
                                                            Manage Menu Items
                                                        </button>
                                                    </div>
                                                }

                                                {
                                                    orderCart.menuItems.map((menuItem, pKey) => <div className="o-product-item">
                                                        <div className="o-product-field">
                                                            <div className="o-product-image" style={{ backgroundImage: `url("${getImageURL(menuItem.image)}")` }}>

                                                            </div>
                                                        </div>

                                                        <div className="o-product-field" style={{ flex: 1 }}>
                                                            <span className="o-product-title">
                                                                {menuItem.name}
                                                            </span>
                                                            <span className="o-product-description">
                                                                {menuItem.shortDescription}
                                                            </span>
                                                            <span>
                                                                {menuItem.addons && menuItem.addons.map((addon, aKey) => <div className="o-product-addon-container" key={aKey}>
                                                                    <div className="o-product-child-addon-container">
                                                                        - <div className="o-product-child-addon">
                                                                            <span className="mr-1">{addon.name}</span>
                                                                            {addon.additionalPrice > 0 ? currencyDisplayHelper(infosetting, addon.additionalPrice) : null}
                                                                        </div>
                                                                    </div>
                                                                </div>)}
                                                            </span>
                                                        </div>


                                                        <div className="o-product-field">
                                                            {order.status == "Placed" ?
                                                                <div className="o-product-edit">
                                                                    <div className="o-product-qty o-product-title">
                                                                        <i className="fa fa-plus-circle" onClick={() => addToCart(menuItem)}></i>
                                                                        {menuItem.cartQty}
                                                                        <i className="fa fa-minus-circle" onClick={() => removeFromCart(menuItem)}></i>
                                                                    </div>
                                                                    <span className="o-product-title">
                                                                        {currencyDisplayHelper(infosetting, menuItem.price)}
                                                                    </span>
                                                                </div>
                                                                :
                                                                <span className="o-product-title">
                                                                    {menuItem.cartQty} x {currencyDisplayHelper(infosetting, menuItem.price)}
                                                                </span>
                                                            }
                                                        </div>
                                                        <div className="o-product-field" style={{ flex: 0.1 }}>
                                                            <span className="o-product-title">
                                                                {currencyDisplayHelper(infosetting, menuItem.cartItemPrice)}
                                                            </span>
                                                        </div>
                                                    </div>)
                                                }

                                                {order.notes !== "" && <div className="o-notes">
                                                    <div className="alert alert-warning">
                                                        Notes: {order.notes}
                                                    </div>
                                                </div>}

                                                {order.status === "Cancelled" && <div className="o-notes">
                                                    <div className="alert alert-danger">
                                                        Cancellation Reason: {order.cancellationNotes}
                                                    </div>
                                                </div>}


                                                <div className="o-total-container">
                                                    <div className="col-sm-6"></div>
                                                    <div className="col-sm-6">
                                                        <div className="o-total-content">
                                                            <div className="o-total-item">
                                                                <span className="o-total-label">
                                                                    Sub Total
                                                                </span>
                                                                <span className="o-total">
                                                                    {currencyDisplayHelper(infosetting, orderCart.subTotal)}
                                                                </span>
                                                            </div>
                                                            <div className="o-total-item">
                                                                <span className="o-total-label">
                                                                    Service Fee
                                                                </span>
                                                                <span className="o-total">
                                                                    {currencyDisplayHelper(infosetting, orderCart.serviceCharges)}
                                                                </span>
                                                            </div>
                                                            <div className="o-total-item">
                                                                <span className="o-total-label">
                                                                    Discount {order.commissionType === "Percentage" && '%'}
                                                                </span>
                                                                <span className="o-total">
                                                                    {currencyDisplayHelper(infosetting, orderCart.discount)}
                                                                </span>
                                                            </div>


                                                            <div className="o-total-item">
                                                                <span className="o-total-label">
                                                                    Commision Fee
                                                                </span>
                                                                <span className="o-total">
                                                                    {currencyDisplayHelper(infosetting, orderCart.commission)}
                                                                </span>
                                                            </div>

                                                            <div className="o-total-item">
                                                                <span className="o-total-label">
                                                                    Earning  ({order.restaurantEarningTransferred ? `Transferred` : `Pending`})
                                                                </span>
                                                                <span className="o-total">
                                                                    {currencyDisplayHelper(infosetting, order.restaurantEarning)}
                                                                </span>
                                                            </div>
                                                            <div className="o-total-item">
                                                                <span className="o-total-label">
                                                                    Waiter Tip ({order.waiterTipTransferred ? `Transferred` : `Pending`})
                                                                </span>
                                                                <span className="o-total">
                                                                    {currencyDisplayHelper(infosetting, order.waiterTip)}
                                                                </span>
                                                            </div>
                                                            
                                                            <div className="o-total-item primary-text">
                                                                <span className="o-total-label">
                                                                    Net Total
                                                                </span>
                                                                <span className="o-total">
                                                                    {currencyDisplayHelper(infosetting, orderCart.netTotal)}
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* {order.status === "Completed" && <div className="co-status-container">
                                                    <div className="co-status-heading">
                                                        Rating
                                                    </div>
                                                    <div className="co-status-option-container">
                                                        <RatingSelector value={order.rating} />
                                                    </div>
                                                </div>} */}

                                                {/* {
                                                    (order.status !== "Completed" && order.status !== "Cancelled" && !(order.status === "Confirmed" && order.paymentOptionObj.name === "Card Payment")) &&
                                                    <div className="o-status-container">
                                                        <div className="o-status-heading">
                                                            Action
                                                        </div>
                                                        <div className="o-status-option-container">
                                                            {order.status === "Placed" && <React.Fragment>
                                                                <StatusButton
                                                                    changeStatus={changeStatus} changingStatus={changingStatus}
                                                                    display="Confirm" value="Confirmed" />

                                                                <CancellationNotesModal onSubmit={handleCancellationNotesSubmit} />

                                                                <button className="hide" data-toggle="modal" data-target={`#cancellationNotesModal`} id="cancellationNotesModalOpener"></button>
                                                                <StatusButton
                                                                    // changeStatus={changeStatus} 
                                                                    onClick={() => window.document.getElementById("cancellationNotesModalOpener").click()}
                                                                    changingStatus={changingStatus}
                                                                    display="Cancel" value="Cancelled" />
                                                            </React.Fragment>
                                                            }
                                                            {((order.status === "Confirmed" && order.paymentOptionObj.name === "Cash on Delivery") || order.status === "Payment Completed") &&
                                                                <StatusButton
                                                                    changeStatus={changeStatus} changingStatus={changingStatus}
                                                                    display="Dispatched" value="On The Way" />
                                                            }

                                                            {order.status === "On The Way" && <React.Fragment>
                                                                <StatusButton
                                                                    changeStatus={changeStatus} changingStatus={changingStatus}
                                                                    display="Completed" value="Completed" />
                                                            </React.Fragment>
                                                            }
                                                        </div>
                                                    </div>
                                                } */}

                                            </div>
                                        }
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="o-cl-container">
                                            <div id={mapId} style={{ height: `400px`, width: `100%`, marginBottom: `30px`, zIndex: 1 }}></div>

                                            {/* <DeliveryAddressEditModal onDeliverySubmit={handleDeliverySubmit} data={order.deliveryInfo} /> */}
                                            <div className="o-cl-content">
                                                <div className="o-cl-item">
                                                    <div className="o-cl-head">
                                                        <span className="o-cl-heading">Customer Name</span>
                                                    </div>
                                                    <div className="o-cl-value">
                                                        {`${order.reservationInfo.name}`}
                                                    </div>
                                                </div>

                                                <div className="o-cl-item">
                                                    <div className="o-cl-head">
                                                        <span className="o-cl-heading">Phone Number</span>
                                                    </div>
                                                    <div className="o-cl-value">
                                                        {order.reservationInfo.phoneNo}
                                                    </div>
                                                </div>

                                                <div className="o-cl-item">
                                                    <div className="o-cl-head">
                                                        <span className="o-cl-heading">Seating</span>
                                                    </div>
                                                    <div className="o-cl-value">
                                                        Adults: {order.reservationInfo.numberOfPersonAdults} - Children: {order.reservationInfo.numberOfPersonChildren}
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }


        </div>
    );
};

function mapStateToProps(state) {
    const { authentication } = state;
    return authentication;
}


export default connect(mapStateToProps)(withRouter(Order));