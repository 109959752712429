/* eslint-disable import/first */
import React from "react";
import { DataTable, Detail, Manage, } from "./_pages/Authenticated/CRUD";

// import { DataTable as Categories, Detail as Category , Manage as CategoryManage ,setRoute as setRouteCategory} from "./_pages/Authenticated/CRUD";
// import { DataTable as Amenities, Detail as Amenity , Manage as AmenityManage ,setRoute as setRouteAmenity} from "./_pages/Authenticated/CRUD";

// import { CategoryManage } from "./_pages/Authenticated/Categories/CategoryManage";
// import { Category } from "./_pages/Authenticated/Categories/Category";
import { AdminManage } from "./_pages/Authenticated/Admin/AdminManage";
import { UserCustomNotifications } from "./_pages/Authenticated/UserCustomNotifications/UserCustomNotifications";
import { UserCustomNotification } from "./_pages/Authenticated/UserCustomNotifications/UserCustomNotification";
import { UserCustomNotificationManage } from "./_pages/Authenticated/UserCustomNotifications/UserCustomNotificationManage";
import { DistributeProfit } from "./_pages/Authenticated/DistributeProfit";
import Orders from "./_pages/Authenticated/Orders";
import Order from "./_pages/Authenticated/Order";
import MerchantAnalytics from "./_pages/Authenticated/MerchantAnalytics";
import AbandonedCart from "./_pages/Authenticated/AbandonedCart";

const Dashboard = React.lazy(() => import("./views/Dashboard"));

// const Console = React.lazy(() => import("./views/Console"));
const routes = [
  { path: "/admin", exact: true, name: "Home" },
  { path: "/manage", exact: true, name: "Admin Manage", component: AdminManage },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },

  // { path: "/users", exact: true, name: "Users", component: Users },
  // { path: "/user/:id/packages", exact: true, name: "User Packages", component: UserPackages },
  // { path: "/user/filter/:type", exact: true, name: "Filter Users", component: Users },
  // { path: "/users/:id", exact: true, name: "User Details", component: User },

  { path: "/users", exact: true, component: DataTable },
  { path: "/user/add", exact: true, name: "Add User", component: Manage },
  { path: "/user/edit/:id", exact: true, name: "Edit User", component: Manage },
  { path: "/users/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/users/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/abandonedcarts", exact: true, name: "Abandoned Cart", component: AbandonedCart },

  { path: "/user-custom-notifications", exact: true, name: "User Custom Notifications", component: UserCustomNotifications },
  { path: "/user-custom-notifications/detail/:id", exact: true, name: "Details", component: UserCustomNotification },
  { path: "/user-custom-notification/add", exact: true, name: "New Custom Notification", component: UserCustomNotificationManage },
  { path: "/user-custom-notification/edit/:id", exact: true, name: "Edit User Custom Notification", component: UserCustomNotificationManage },
  { path: "/user-custom-notifications/delete/:id", exact: true, name: "Delete", component: UserCustomNotification },

  { path: "/restaurants", exact: true, component: DataTable },
  { path: "/restaurant/add", exact: true, name: "Add Restaurant", component: Manage },
  { path: "/restaurant/edit/:id", exact: true, name: "Edit Restaurant", component: Manage },
  { path: "/restaurants/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/restaurants/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/restaurant-categories", exact: true, component: DataTable },
  { path: "/restaurant-category/add", exact: true, name: "Add Restaurant Category", component: Manage },
  { path: "/restaurant-category/edit/:id", exact: true, name: "Edit Restaurant Category", component: Manage },
  { path: "/restaurant-categories/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/restaurant-categories/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/restaurant-schedules", exact: true, component: DataTable },
  { path: "/restaurant-schedule/add", exact: true, name: "Add Restaurant Schedule", component: Manage },
  { path: "/restaurant-schedule/edit/:id", exact: true, name: "Edit Restaurant Schedule", component: Manage },
  { path: "/restaurant-schedules/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/restaurant-schedules/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/menu-items", exact: true, component: DataTable },
  { path: "/menu-item/add", exact: true, name: "Add Menu Item", component: Manage },
  { path: "/menu-item/edit/:id", exact: true, name: "Edit Menu Item", component: Manage },
  { path: "/menu-items/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/menu-items/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/tables", exact: true, component: DataTable },
  { path: "/table/add", exact: true, name: "Add Restaurant Waiter", component: Manage },
  { path: "/table/edit/:id", exact: true, name: "Edit Restaurant Waiter", component: Manage },
  { path: "/tables/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/tables/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/waiters", exact: true, component: DataTable },
  { path: "/waiter/add", exact: true, name: "Add Restaurant Waiter", component: Manage },
  { path: "/waiter/edit/:id", exact: true, name: "Edit Restaurant Waiter", component: Manage },
  { path: "/waiters/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/waiters/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/pages", exact: true, component: DataTable },
  { path: "/page/add", exact: true, name: "Add Page", component: Manage },
  { path: "/page/edit/:id", exact: true, name: "Edit Page", component: Manage },
  { path: "/pages/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/pages/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/news-categories", exact: true, component: DataTable },
  { path: "/news-category/add", exact: true, name: "Add News Category", component: Manage },
  { path: "/news-category/edit/:id", exact: true, name: "Edit News Category", component: Manage },
  { path: "/news-categories/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/news-categories/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/news", exact: true, component: DataTable },
  { path: "/news/add", exact: true, name: "Add News", component: Manage },
  { path: "/news/edit/:id", exact: true, name: "Edit News", component: Manage },
  { path: "/news/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/news/detail/:id", exact: true, name: "Details", component: Detail },



  { path: "/supportmessages", exact: true, component: DataTable },
  { path: "/supportmessages/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/supportmessages/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/partnermessages", exact: true, component: DataTable },
  { path: "/partnermessages/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/partnermessages/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/distribute-profit", exact: true, component: DistributeProfit },


  { path: "/currencies", exact: true, component: DataTable },
  { path: "/currency/add", exact: true, name: "Add Currency", component: Manage },
  { path: "/currency/edit/:id", exact: true, name: "Edit Currency", component: Manage },
  { path: "/currencies/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/currencies/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/cities", exact: true, component: DataTable },
  { path: "/city/add", exact: true, name: "Add City", component: Manage },
  { path: "/city/edit/:id", exact: true, name: "Edit City", component: Manage },
  { path: "/cities/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/cities/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/business-categories", exact: true, component: DataTable },
  { path: "/business-category/add", exact: true, name: "Add Business Category", component: Manage },
  { path: "/business-category/edit/:id", exact: true, name: "Edit Business Category", component: Manage },
  { path: "/business-categories/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/business-categories/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/service-categories", exact: true, component: DataTable },
  { path: "/service-category/add", exact: true, name: "Add Service Category", component: Manage },
  { path: "/service-category/edit/:id", exact: true, name: "Edit Service Category", component: Manage },
  { path: "/service-categories/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/service-categories/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/product-categories", exact: true, component: DataTable },
  { path: "/product-category/add", exact: true, name: "Add Product Category", component: Manage },
  { path: "/product-category/edit/:id", exact: true, name: "Edit Product Category", component: Manage },
  { path: "/product-categories/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/product-categories/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/product-sub-categories", exact: true, component: DataTable },
  { path: "/product-sub-category/add", exact: true, name: "Add Product Sub Category", component: Manage },
  { path: "/product-sub-category/edit/:id", exact: true, name: "Edit Product Sub Category", component: Manage },
  { path: "/product-sub-categories/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/product-sub-categories/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/brands", exact: true, component: DataTable },
  { path: "/brand/add", exact: true, name: "Add Brand", component: Manage },
  { path: "/brand/edit/:id", exact: true, name: "Edit Brand", component: Manage },
  { path: "/brands/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/brands/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/products", exact: true, component: DataTable },
  { path: "/product/add", exact: true, name: "Add Product", component: Manage },
  { path: "/product/edit/:id", exact: true, name: "Edit Product", component: Manage },
  { path: "/products/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/products/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/product-addons", exact: true, component: DataTable },
  { path: "/product-addon/add", exact: true, name: "Add Product Addon", component: Manage },
  { path: "/product-addon/edit/:id", exact: true, name: "Edit Product Addon", component: Manage },
  { path: "/product-addons/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/product-addons/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/orders", exact: true, component: Orders },
  { path: "/order/:id", exact: true, component: Order },

  { path: "/merchant-analytics", exact: true, component: MerchantAnalytics },


  { path: "/merchant-products", exact: true, component: DataTable },
  { path: "/merchant-product/add", exact: true, name: "Add Merchant Product", component: Manage },
  { path: "/merchant-product/edit/:id", exact: true, name: "Edit Merchant Product", component: Manage },
  { path: "/merchant-products/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/merchant-products/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/merchant-bank-accounts", exact: true, component: DataTable },
  { path: "/merchant-bank-account/add", exact: true, name: "Add Merchant Product", component: Manage },
  { path: "/merchant-bank-account/edit/:id", exact: true, name: "Edit Merchant Product", component: Manage },
  { path: "/merchant-bank-accounts/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/merchant-bank-accounts/detail/:id", exact: true, name: "Details", component: Detail },



  { path: "/merchant-banners", exact: true, component: DataTable },
  { path: "/merchant-banner/add", exact: true, name: "Add Merchant Banner", component: Manage },
  { path: "/merchant-banner/edit/:id", exact: true, name: "Edit Merchant Banner", component: Manage },
  { path: "/merchant-banners/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/merchant-banners/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/merchant-schedules", exact: true, component: DataTable },
  { path: "/merchant-schedule/add", exact: true, name: "Add Merchant Schedule", component: Manage },
  { path: "/merchant-schedule/edit/:id", exact: true, name: "Edit Merchant Schedule", component: Manage },
  { path: "/merchant-schedules/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/merchant-schedules/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/merchant-subs", exact: true, component: DataTable },
  { path: "/merchant-sub/add", exact: true, name: "Add Merchant Sub Account", component: Manage },
  { path: "/merchant-sub/edit/:id", exact: true, name: "Edit Merchant Sub Account", component: Manage },
  { path: "/merchant-subs/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/merchant-subs/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/merchant-modules", exact: true, component: DataTable },
  { path: "/merchant-module/add", exact: true, name: "Add Module", component: Manage },
  { path: "/merchant-module/edit/:id", exact: true, name: "Edit Module", component: Manage },
  { path: "/merchant-modules/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/merchant-modules/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/modules", exact: true, component: DataTable },
  { path: "/module/add", exact: true, name: "Add Module", component: Manage },
  { path: "/module/edit/:id", exact: true, name: "Edit Module", component: Manage },
  { path: "/modules/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/modules/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/admins", exact: true, component: DataTable },
  { path: "/admin/add", exact: true, name: "Add Admin", component: Manage },
  { path: "/admin/edit/:id", exact: true, name: "Edit Admin", component: Manage },
  { path: "/admins/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/admins/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/payment-options", exact: true, component: DataTable },
  { path: "/payment-option/add", exact: true, name: "Add Payment Option", component: Manage },
  { path: "/payment-option/edit/:id", exact: true, name: "Edit Payment Option", component: Manage },
  { path: "/payment-options/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/payment-options/detail/:id", exact: true, name: "Details", component: Detail },

  { path: "/payment-gateways", exact: true, component: DataTable },
  { path: "/payment-gateway/add", exact: true, name: "Add Payment Gateway", component: Manage },
  { path: "/payment-gateway/edit/:id", exact: true, name: "Edit Payment Gateway", component: Manage },
  { path: "/payment-gateways/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/payment-gateways/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/promotions", exact: true, component: DataTable },
  { path: "/promotion/add", exact: true, name: "Add Promo Code", component: Manage },
  { path: "/promotion/edit/:id", exact: true, name: "Edit Promo Code", component: Manage },
  { path: "/promotions/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/promotions/detail/:id", exact: true, name: "Details", component: Detail },


  { path: "/sliders", exact: true, component: DataTable },
  { path: "/slider/add", exact: true, name: "Add Slider", component: Manage },
  { path: "/slider/edit/:id", exact: true, name: "Edit Slider", component: Manage },
  { path: "/sliders/delete/:id", exact: true, name: "Delete", component: Detail },
  { path: "/sliders/detail/:id", exact: true, name: "Details", component: Detail },

  // { path: '/payment-settings', exact: true, name: 'Payment Settings', component: PaymentSettings },


  { path: "/infosetting/add", exact: true, name: "Add Setting", component: Manage },
  { path: "/setting/edit/:id", exact: true, name: "Edit Setting", component: Manage },
  { path: "/infosetting/edit/:id", exact: true, name: "Info Setting", component: Manage }
];

export default routes;
