const calculateCartTotal = (cart, infosetting, merchant) => {
    let subTotal = 0.00, netTotal = 0.00, discount = 0.00;
    let nCart = JSON.parse(JSON.stringify(cart));
    let serviceCharges = merchant.cityObj.deliveryCharges;
    if (infosetting) {
        if (nCart.products.length > 0 && infosetting) {
            nCart.products.forEach(c => {
                subTotal += c.cartItemPrice;
            });
            netTotal = subTotal + serviceCharges;
        }
        if (nCart.coupan && netTotal > 0) {
            discount = parseFloat(Math.round((netTotal / 100) * nCart.coupan.discountPercentage));
            netTotal = netTotal - discount;
        }
        let commissionType = merchant.commissionType;
        let commission = commissionType === "Percentage" ? Math.round((parseFloat(subTotal) / 100) * merchant.commissionValue) : merchant.commissionValue;
        let merchantEarning = subTotal - commission;
        nCart = { ...nCart, netTotal, subTotal, discount, serviceCharges: serviceCharges, commission, commissionType, merchantEarning };
    }

    return nCart;
}
export default calculateCartTotal;