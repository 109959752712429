import React, { useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import getImageURL from "../../../_helpers/imageHelper";
import './productmodal.css';
import horizontalDragableScroll from "../../../_helpers/horizontalDragableScroll";
import { apiService } from "../../../_services/api.service";

const AddProductModal = ({ merchant, cart, infosetting, addToCart, addToWithAddonsCart, removeFromCart }) => {

    const defaultSubCategory = { _id: "all_items", name: "All Items" };
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setselectedCategory] = useState("all_products");
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategory, setselectedSubCategory] = useState("all_items");
    const [products, setProducts] = useState([]);
    const [productsFilter, setProductsFilter] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [suggestionBlockVisible, setSuggestionBlockVisible] = useState(false);
    const [suggestionlist, setSuggestionlist] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [mobilemenucategories, setMobilemenucategories] = useState(false);


    useEffect(() => {
        horizontalDragableScroll("apm-s-store-sub-categories-container");
    }, []);
    useEffect(() => {
        if (merchant) {
            apiService.filter("merchant-products", { query: { merchant } })
                .then(pro => {
                    console.log({ pro });
                    setProducts(pro); setLoaded(true)
                });
        }
    }, [merchant]);



    useEffect(() => {
        if (products.length > 0) {
            if (selectedCategory !== "all_products") {
                let catList = categoriesFromProducts(products);
                let selectedCategoryTemp = catList.find(c => c._id === selectedCategory);
                if (selectedCategoryTemp && selectedCategoryTemp.confimation18) {
                    if (!window.confirm("Are you 18+")) {
                        setselectedCategory("all_products");
                        return;
                    }
                }

                apiService.filter('product-sub-categories', { query: { category: selectedCategory } }).then(sc => {
                    sc = [defaultSubCategory, ...sc];
                    setSubCategories(sc);
                })
            } else {
                setSubCategories([defaultSubCategory])
            }


            doFilterProducts(products, { category: selectedCategory, subCategory: selectedSubCategory });

        }
    }, [selectedCategory, selectedSubCategory, products]);

    useEffect(() => {
        if (searchValue !== "" && products.length > 0) {
            let searchVal = searchValue.toLowerCase();
            let suggestion = products
                .filter(p =>
                    p.productObj.name.toLowerCase().includes(searchVal)
                    || p.productObj.slug.includes(searchVal)
                    || p.productObj.tags.includes(searchVal)
                );

            setSuggestionlist(suggestion);
            setSuggestionBlockVisible(true);
        } else {
            setSuggestionlist([]);
            setSuggestionBlockVisible(false);
        }
    }, [searchValue, products]);

    useEffect(() => {
        setCategories([]);
        setCategories(categoriesFromProducts(products));
    }, [products]);


    const doFilterProducts = (products, query) => {
        let filteredProducts = [];
        if (query.category && query.category != "all_products" && query.subCategory && query.subCategory === "all_items") {
            filteredProducts = products.filter(p => query.category && query.category === p.category);
        } else if (query.subCategory && query.subCategory !== "all_items") {
            filteredProducts = products.filter(p =>
                query.category && query.category === p.category
                && query.subCategory && query.subCategory === p.subCategory
            );
        } else if (query.brand) {
            filteredProducts = products.filter(p =>
                query.brand && query.brand === p.brand);
        } else {
            filteredProducts = products.filter(p => !p.categoryObj.confimation18);
        }

        setProductsFilter(filteredProducts)
        // console.log({ filteredProducts });
    }

    const categoriesFromProducts = (pros) => {
        let catList = [];
        pros.forEach(({ productObj, categoryObj }) => {
            let foundCatList = catList.findIndex(c => c._id === productObj.category);
            if (foundCatList === -1) {
                catList.push(categoryObj)
            }
        });
        return catList;
    }

    const handleSubCategoryClick = (sc) => {
        if (sc._id != "all_items") {
            setselectedCategory(sc.categoryObj._id);
            setselectedSubCategory(sc._id)
        } else {
            if (selectedCategory) {
                setselectedSubCategory("all_items")
            }
        }
    }

    const handleAddtoCart = (_id, price) => {
        let productData = products.find(g => g._id === _id);
        if (productData) {
            productData = JSON.parse(JSON.stringify(productData));
            productData["price"] = price;
            addToCart(productData);
        }
    }
    const handleAddtoCartwithAddon = (_id, unitPrice, qty, totalPrice, selectedAddons) => {
        let productData = products.find(g => g._id === _id);
        if (productData) {
            productData = JSON.parse(JSON.stringify(productData));
            productData["price"] = unitPrice;
            productData["cartQty"] = qty;
            productData["cartItemPrice"] = totalPrice;
            productData["addons"] = selectedAddons;
            addToWithAddonsCart(productData);
        }
    }
    const handleRemoveFromCart = (_id) => {
        let productData = products.find(g => g._id === _id);
        if (productData) {
            removeFromCart(productData);
        }
    }

    const handleSubmit = () => {

        window.document.getElementById('addProductModalClose').click();

    }
    return (
        <div className="modal fade" id={`addProductModal`} tabindex="-1" role="dialog" aria-labelledby="addProductModalTitle" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title primary-text" id="addProductModalTitle">Manage Products</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="addProductModalClose">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body center-content">
                        {
                            loaded ? <div className="apm-s-store-container">
                                <div className={`apm-s-store-categories-container ${mobilemenucategories ? "apm-s-store-mobile-categories-container" : ''}`}
                                    onClick={() => setMobilemenucategories(!mobilemenucategories)}
                                >
                                    <div className="apm-s-store-all-products" onClick={() => {
                                        setselectedCategory("all_products");
                                        setselectedSubCategory("all_items");
                                    }}>
                                        View all products
                                    </div>
                                    {
                                        categories && categories.map((cat, k) => <div
                                            className={`apm-s-store-category-item ${cat._id === selectedCategory ? 'active' : ''}`}
                                            onClick={() => setselectedCategory(cat._id)}
                                            style={{ backgroundImage: `url(${getImageURL(cat.image)})` }}
                                        >
                                            <div className="apm-s-store-category-content">{cat.name}</div>
                                        </div>)
                                    }

                                </div>
                                <div className={`apm-s-store-inner-container ${mobilemenucategories ? "apm-s-store-mobile-categories-inner-container" : ''}`} >
                                    <div className="apm-s-store-search-container">
                                        <div className="apm-s-store-mobile-menu-icon center-content" onClick={() => setMobilemenucategories(!mobilemenucategories)}>
                                            <i class="fas fa-bars"></i>
                                        </div>

                                        <div className="apm-s-store-search-field-group">
                                            <input className="apm-s-store-search-field"
                                                placeholder="Search for any product ..."
                                                value={searchValue}
                                                onChange={({ target }) => setSearchValue(target.value)}
                                            />
                                            <div className="apm-s-store-search-button center-content">
                                                <i className="fa fa-search"></i>
                                            </div>
                                        </div>
                                        {
                                            suggestionBlockVisible && <div className="apm-s-store-search-suggest-container" onClick={() => setSuggestionBlockVisible(false)}>
                                                {
                                                    suggestionlist.map((sl, slKey) => <div
                                                        key={slKey}
                                                        className="apm-s-store-search-suggest-item" onClick={() => {
                                                            setselectedCategory(sl.category);
                                                            setselectedSubCategory(sl.subCategory)
                                                        }}>
                                                        {sl.productObj.name}
                                                    </div>)
                                                }


                                            </div>

                                        }
                                    </div>
                                    <div className="apm-s-store-sub-categories-container">
                                        {
                                            subCategories.map((sc, sKey) => <div
                                                onClick={() => handleSubCategoryClick(sc)}
                                                className={`apm-s-store-sc-item ${sc._id === selectedSubCategory ? 'active' : ''}`}>
                                                <span>{sc.name}</span>
                                            </div>)
                                        }

                                    </div>
                                    <div className="apm-s-store-products-conatainer">
                                        {
                                            productsFilter.length > 0 ?
                                                productsFilter.map((pf, pkKey) => (
                                                    <ProductCard {...pf} key={pkKey} infosetting={infosetting}
                                                        classPrefix="apm-s"
                                                        onAddtoCart={handleAddtoCart}
                                                        onAddtoCartwithAddon={handleAddtoCartwithAddon}
                                                        onRemoveFromCart={handleRemoveFromCart}
                                                        cartProduct={cart.products.find(c => c._id == pf._id)}
                                                        duplicateProductCount={cart.products.filter(c => c._id == pf._id).length - 1}
                                                    />
                                                ))
                                                :
                                                <div className="apm-s-products-not-found-container center-content">
                                                    <i aria-hidden="true" class="fa fa-shopping-cart s-notfound-icon"></i>

                                                    <div className="sl-prompt-content">
                                                        <span className="primary-text font-weight-bold">Products</span>
                                                        <span className="ml-1">not found.</span>
                                                    </div>
                                                </div>
                                        }
                                    </div>

                                </div>
                            </div>
                                :
                                null
                        }

                    </div>
                    <div className="modal-footer center-content ">
                        <button type="button" className="btn btn-primary ct-submit-button" onClick={handleSubmit} >Close</button>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default AddProductModal;