export default {
  items: [
    {
      name: "Dashboard", url: "/dashboard", icon: "icon-speedometer",
      badge: {
        variant: "info",
        text: "NEW"
      }
    },
    {
      name: "Users", url: "/users", icon: "fa fa-user",
      children: [
        {
          name: "Add Users", url: "/user/add", icon:"fa fa-plus-circle", class: 'ml-2'
        },
        {
          name: "Users List", url: "/users", icon:"fa fa-user", class: 'ml-2'
        },
        {
          name: "Notifications", url: "/user-custom-notifications", icon:"fa fa-bell", class: 'ml-2'
        },
      ]
    },
    {
      name: "Restaurants", url: "/restaurants", icon: "fas fa-utensils",
      children: [
        {
          name: "Categories", url: "/restaurant-categories", icon:"fas fa-concierge-bell", class: 'ml-2'
        },
        {
          name: "Add Restaurants", url: "/restaurant/add", icon:"fa fa-plus-circle", class: 'ml-2'
        },
        {
          name: "Restaurants List", url: "/restaurants", icon:"fas fa-utensils", class: 'ml-2'
        }
      ]
    },
    {
      name: "Orders", url: "/orders", icon: "fa fa-table",
    },
    {
      name: 'Promotions', url: '/promotions', icon: 'fa fa-tag',
    },
    {
      // title: true,
      name: 'Setup', icon: 'fa fa-wrench',
      children: [
        {
          name: 'Pages', url: '/pages', icon:'fa fa-file', class: 'ml-2'
        },
        {
          name: 'Admins', url: '/admins', icon:'fa fa-users', class: 'ml-2'
        },
        {
          name: 'Currencies', url: '/currencies', icon:'fa fa-coins', class: 'ml-2'
        },
        {
          name: 'Configuration', url: '/setting/edit/64d3d7a500998df9b7983e4c?single=true', icon:'fa fa-wrench', class: 'ml-2'
        },
        {
          name: 'General Setting', url: '/infosetting/edit/645429b855772633d57323b5?single=true', icon:'fa fa-wrench', class: 'ml-2'
        }
      ]
    }
  ]
};
