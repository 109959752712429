import React, { useEffect, useState } from 'react';
import { apiService } from '../../../_services/api.service';
import { Card, CardBody, CardHeader, Col, Row, Table, Badge } from "reactstrap";
import PaginationComponent from "react-reactstrap-pagination";
import socketUpdater from '../../../_helpers/socketUpdater';
import { toast, ToastContainer } from 'react-toastify';

const AbandonedCart = () => {
    const [activePagination, setactivePagination] = useState({
        pageLoading: true,
        selectedPage: 1,
        pageSize: 10,
        maxPaginationNumbers: 9
    });
    const [list, setList] = useState(null);

    useEffect(() => {
        getAll();
        socketUpdater('abandonedCart', null, null, getAll)
    }, []);

    const getAll = () => {
        apiService.getAll('abandonedcarts').then(res => {
            for (let i = 0; i < res.length; i++) {
                res[i]["selected"] = true;
            }
            setList(res);
        });
    }
    const getSelectedListItemCount = () => {
        return list.filter(m => m.selected).length
    }

    const handleSelectedActivePage = selectedPage => {
        let activePaginationTemp = JSON.parse(JSON.stringify(activePagination));
        activePaginationTemp.selectedPage = selectedPage;
        setactivePagination(activePaginationTemp);
    };
    const handleItemSelectClick = (i) => {
        let listArr = JSON.parse(JSON.stringify(list));
        listArr[i].selected = !listArr[i].selected;
        setList(listArr);
    }
    const handleOnSelectAllClick = (e) => {
        let count = getSelectedListItemCount();
        let listArr = JSON.parse(JSON.stringify(list))
        for (let i = 0; i < listArr.length; i++) {
            listArr[i]["selected"] = (count !== list.length);
        }
        setList(listArr);
    }
    const handleSendNotification = () => {
        let lf = list.filter(m => m.selected)
        apiService.add('abandonedcarts/send-notification', lf)
            .then(res => {
                toast.success(`Successfully send notifications to ${lf.length} devices`);
            })
            .catch(e => {
                toast.error("An error occured while sending notifications")
            })
    }

    return (
        <div className="animated fadeIn">
            <ToastContainer></ToastContainer>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardHeader>
                            <i className="fa fa-shopping-cart"></i> Abandoned Cart
                            {list && list.length > 0 && <button
                                className="btn btn-primary btn-sm"
                                style={{ float: "right" }}
                                disabled={getSelectedListItemCount() === 0}
                                onClick={handleSendNotification}
                            >
                                Send Notification
                            </button>}
                        </CardHeader>
                        {list ? list.length > 0 ? <CardBody>
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input type="checkbox" checked={getSelectedListItemCount() === list.length} value={true} onChange={handleOnSelectAllClick}></input>
                                        </th>
                                        <th scope="col">OS</th>
                                        <th scope="col">Products</th>
                                        <th scope="col">Net Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.map((item, key) => <tr key={key}>
                                        <td>  <input type="checkbox" checked={item.selected} onChange={() => handleItemSelectClick(key)} /></td>
                                        <td>{item.os}</td>
                                        <td>{item.cart.products.length}</td>
                                        <td>{item.cart.netTotal}</td>
                                    </tr>)}
                                </tbody>
                            </Table>
                            <PaginationComponent
                                totalItems={list.length}
                                pageSize={activePagination.pageSize}
                                onSelect={handleSelectedActivePage}
                                maxPaginationNumbers={
                                    activePagination.maxPaginationNumbers
                                }
                                activePage={activePagination.selectedPage}
                            />
                        </CardBody> :
                            <CardBody>
                                <div>
                                    <span>No Abdoned Cart Entries Found!</span>
                                </div>
                            </CardBody> : null}

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default AbandonedCart;