const productPriceDiscoutDeductionHelper = (merchantProduct) => {
    let retPrice = 0;
    if (merchantProduct.discountActive) {
        if (merchantProduct.discountType === "Percentage") {
            retPrice = merchantProduct.price - ((merchantProduct.price / 100) * merchantProduct.discountValue);
        } else {
            retPrice = merchantProduct.price - merchantProduct.discountValue
        }
    } else {
        retPrice = merchantProduct.price;
    }
    return retPrice;

}
export default productPriceDiscoutDeductionHelper;