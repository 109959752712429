import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import currencyDisplayHelper from "../../../_helpers/currencyDisplayHelper";
import getImageURL from "../../../_helpers/imageHelper";
import productPriceDiscoutDeductionHelper from "../../../_helpers/productPriceDiscoutDeductionHelper";
import { apiService } from "../../../_services/api.service";

const ProductDetailModal = ({ _id, productObj, infosetting, onClickAddtoCart, productPrice }) => {
    return (
        <div className="modal fade" id={`productModal${_id}`} tabindex="-1" role="dialog" aria-labelledby="productModalTitle" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title primary-text" id="productModalTitle">Product Details</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="pm-title-container center-content primary-text text-center">
                            <h4>{productObj.name} ({productObj.packingDetail})</h4>
                        </div>
                        <div className="pm-detail-image" style={{ backgroundImage: `url("${getImageURL(productObj.image)}")` }}>

                        </div>
                        {/* <ProductSlider id={`productSlider${_id}`} sliders={productObj.images} /> */}


                        <div className="pm-description" dangerouslySetInnerHTML={{ __html: productObj.longDescription }}>

                        </div>
                    </div>
                    <div className="modal-footer center-content">
                        <button type="button" className="btn btn-primary" onClick={onClickAddtoCart}>Add to Cart ({currencyDisplayHelper(infosetting, productPrice)})</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ProductAddonModal = ({ _id, productObj, addons, infosetting, onClickAddtoCart, productPrice }) => {
    const [localAddons, setlocalAddons] = useState([]);
    const [unitPrice, setUnitPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [qty, setQty] = useState(1);
    useEffect(() => {
        setlocalAddons(addons)
    }, [addons]);

    useEffect(() => {
        let tPrice = productPrice;
        let localAddonsTemp = JSON.parse(JSON.stringify(localAddons));

        for (let index = 0; index < localAddonsTemp.length; index++) {
            const localaddon = localAddonsTemp[index];
            for (let aindex = 0; aindex < localaddon.addons.length; aindex++) {
                const childAddon = localaddon.addons[aindex];
                if (childAddon.selected) {
                    tPrice += childAddon.additionalPrice ? childAddon.additionalPrice : 0;
                }
            }
        }
        setUnitPrice(tPrice);
        setTotalPrice(tPrice * qty)
    }, [localAddons, productPrice, qty]);

    const handleItemPress = (index, cKey) => {
        let localAddonsTemp = JSON.parse(JSON.stringify(localAddons));
        let localAddon = localAddonsTemp[index];

        if (!localAddon.multiple) {
            for (let aindex = 0; aindex < localAddonsTemp[index].addons.length; aindex++) {
                localAddonsTemp[index].addons[aindex]["selected"] = false;
            }
        }

        localAddonsTemp[index].addons[cKey]["selected"] = localAddonsTemp[index].addons[cKey]["selected"] ? false : true;
        setlocalAddons(localAddonsTemp)
    }

    const handleClear = (index) => {
        let localAddonsTemp = JSON.parse(JSON.stringify(localAddons));
        for (let aindex = 0; aindex < localAddonsTemp[index].addons.length; aindex++) {
            localAddonsTemp[index].addons[aindex]["selected"] = false;
        }
        setlocalAddons(localAddonsTemp);
    }
    const handleAddQty = () => {
        setQty(qty + 1)
    }
    const handleMinusQty = () => {
        if (qty > 1) {
            setQty(qty - 1)
        }
    }
    const handleSubmit = () => {
        let unSelectedRadio = 0;
        localAddons.filter(la => !la.multiple).forEach((childAddon) => {
            if (childAddon.addons.filter(ca => ca.selected).length == 0) {
                unSelectedRadio += 1;
                toast.error(`Please select ${childAddon.mainName}`);
            }
        });

        if (unSelectedRadio > 0) {
            return null;
        }

        let localAddonsTemp = JSON.parse(JSON.stringify(localAddons));
        let selectedAddons = [];
        for (let index = 0; index < localAddonsTemp.length; index++) {
            const childAddon = localAddonsTemp[index];
            let childAddonFiltered = childAddon.addons.filter(ca => ca.selected);
            if (childAddonFiltered.length > 0) {
                let newObj = { ...childAddon, addons: childAddonFiltered }
                selectedAddons.push(newObj);
            }
        }
        onClickAddtoCart && onClickAddtoCart(_id, unitPrice, qty, totalPrice, selectedAddons);
        setlocalAddons(addons)

    }
    return (
        <div className="modal fade" id={`productAddonModal${_id}`} tabindex="-1" role="dialog" aria-labelledby="productAddonModalTitle" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title primary-text" id="productAddonModalTitle">Customize {productObj.name}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="pam-container">
                            {
                                localAddons && localAddons.map((addon, key) => <div className="pam-item">
                                    <div className="pam-title-container">
                                        <span className="pam-title">
                                            {addon.mainName}
                                        </span>
                                        {
                                            (addon.addons.filter(ad => ad.selected && ad.selected === true).length > 0) && <div className="pam-clear-button" onClick={() => handleClear(key)}>
                                                <i class="fas fa-times"></i>
                                                <span>Clear</span>
                                            </div>
                                        }

                                    </div>
                                    <div className="pam-list-container">
                                        {
                                            addon.addons.map((childAddon, cKey) => <div className="pam-list-item"
                                                onClick={() => handleItemPress(key, cKey)}>
                                                <div className="pam-list-radio-button">
                                                    {
                                                        addon.multiple ?
                                                            <i className={`${childAddon.selected ? 'fas fa-check-square checked' : 'far fa-square'}`}></i>
                                                            :
                                                            <i className={`${childAddon.selected ? 'fas fa-check-circle checked' : 'far fa-circle'}`}></i>

                                                    }

                                                </div>
                                                <span className="pam-list-name">
                                                    {childAddon.name}
                                                </span>
                                                <span className="pam-list-price">
                                                    {childAddon.additionalPrice > 0 && `(+ ${currencyDisplayHelper(infosetting, childAddon.additionalPrice)})`}
                                                </span>
                                            </div>)
                                        }
                                    </div>
                                </div>)
                            }
                        </div>

                    </div>
                    <div className="modal-footer center-content pam-modal-footer">
                        <div className="pam-qty-container">
                            <i class="fas fa-plus-circle" onClick={handleAddQty}></i>
                            <span>{qty}</span>
                            <i class="fas fa-minus-circle" onClick={handleMinusQty}></i>
                        </div>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" aria-label="Close" onClick={handleSubmit} >Add to Cart  ({currencyDisplayHelper(infosetting, totalPrice)})</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ProductCard = (props) => {
    const { _id, productObj, classPrefix, inStock, discountActive, discountType, discountValue, price, infosetting, cartProduct, duplicateProductCount, onAddtoCart, onAddtoCartwithAddon, onRemoveFromCart } = props;
    let productPrice = productPriceDiscoutDeductionHelper(props);
    const [addons, setAddons] = useState([]);
    const [addonsLoaded, setAddonsLoaded] = useState(false);
    useEffect(() => {
        apiService.filter('product-addons', { query: { product: productObj._id } })
            .then(adns => {
                setAddons(adns);
                setAddonsLoaded(true);
            })
    }, [_id]);
    const onClickAddtoCart = () => {
        if (addonsLoaded && inStock) {
            if (addons.length === 0) {
                onAddtoCart && onAddtoCart(_id, productPrice)
            } else {
                window.document.getElementById(`productAddonModal${_id}Button`).click();
            }
        }
    }
    return (
        <React.Fragment>
            {/* <!-- Modal --> */}
            <ProductDetailModal {...props} productPrice={productPrice} onClickAddtoCart={onClickAddtoCart} />

            <button className="hide" id={`productAddonModal${_id}Button`} data-toggle="modal" data-target={`#productAddonModal${_id}`}>Open</button>
            <ProductAddonModal {...props} productPrice={productPrice} addons={addons} onClickAddtoCart={onAddtoCartwithAddon} />

            <div className={`${classPrefix ? classPrefix : 's'}-store-product-item`}>
                <div className={`${classPrefix ? classPrefix : 's'}-store-product-image-container`}>
                    <div className={`${classPrefix ? classPrefix : 's'}-store-product-image`} style={{ backgroundImage: `url("${getImageURL(productObj.image)}")` }}>
                        <div className={`${classPrefix ? classPrefix : 's'}-store-image-top-content`}>
                            {
                                cartProduct && <React.Fragment>
                                    <div className={`${classPrefix ? classPrefix : 's'}-product-remove-cart-button center-content`} onClick={() => onRemoveFromCart(_id)}>
                                        <i className="fas fa-minus"></i>
                                    </div>
                                    <div className={`${classPrefix ? classPrefix : 's'}-product-cart-qty`} onClick={onClickAddtoCart}>
                                        x{cartProduct.cartQty + duplicateProductCount}
                                    </div>
                                </React.Fragment>
                            }

                        </div>
                        <div style={{ flex: 2 }} onClick={onClickAddtoCart} className={`${classPrefix ? classPrefix : 's'}-product-outofstock-container`}>
                            {!inStock && <div className={`${classPrefix ? classPrefix : 's'}-product-outofstock`}>
                                Out of Stock
                            </div>}
                        </div>
                        <div className={`${classPrefix ? classPrefix : 's'}-store-image-bottom-content`}>
                            <button className={`${classPrefix ? classPrefix : 's'}-store-info-icon`} data-toggle="modal" data-target={`#productModal${_id}`}>
                                <i className="fa fa-info-circle"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`${classPrefix ? classPrefix : 's'}-store-product-content`} onClick={onClickAddtoCart}>
                    <span className={`${classPrefix ? classPrefix : 's'}-store-product-name`}>
                        {productObj.name}
                    </span>

                    <span className={`${classPrefix ? classPrefix : 's'}-store-product-packaging`}>{productObj.packingDetail}</span>

                    <div className={`${classPrefix ? classPrefix : 's'}-store-product-price-container`}>
                        <span className={`${classPrefix ? classPrefix : 's'}-store-product-price ${discountActive ? 'primary-text' : ''}`}>{discountActive ? discountType === "Percentage" ? `${discountValue}% OFF | ` : `FLAT ${currencyDisplayHelper(infosetting, discountValue)} OFF | ` : null}
                            {currencyDisplayHelper(infosetting, productPrice)}</span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ProductCard;