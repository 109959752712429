import io from 'socket.io-client';
import config from '../_config';


const socketUpdater = (modelName, list, setter, trigger) => {
    let socket = io(config.baseUrl, config.socketHeader);
    socket.on("new-" + modelName, (m) => {
        trigger && trigger("new", m);
        if (list) {
            list.push(m);
            setter && setter(list);
        }
    });
    socket.on("update-" + modelName, (m) => {
        trigger && trigger("update", m);
        if (list) {
            let foundIndex = list.findIndex(n => n._id === m._id);
            if (foundIndex !== -1) {
                list[foundIndex] = m;
                setter && setter(list);
            }
        }
    });
    socket.on("delete-" + modelName, (m) => {
        trigger && trigger("delete", m);
        if (list) {
            let foundIndex = list.findIndex(n => n._id === m);
            if (foundIndex !== -1) {
                list.splice(foundIndex, 1);
                setter && setter(list);
            }
        }
    });
}

export default socketUpdater;