const currencyDisplayHelper = (infosettings, value) => {
    if (infosettings) {
        let { currencyObj } = infosettings;
        let retValue = currencyObj[currencyObj.display.toLowerCase()];
        if (currencyObj.placement === "Left") {
            return retValue + value
        } else {
            return value + retValue
        }
    } else {
        return value;
    }
}
export default currencyDisplayHelper;