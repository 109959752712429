import React, { Component, Fragment } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../_config";
import { authHeader } from "../_helpers";
import Axios from "axios";
export default class ParentDropDown extends Component {

    state = {
        dataset: null,
        datasetFiltered: null,
        bluePrint: null,
        fetching: true,
        dropdownActive: false,
    }
    loadViaId = null;
    componentDidMount() {
        let { entityAPI, loadVia, loadViaId } = this.props.bluePrint;
        this.setState({ bluePrint: this.props.bluePrint })

        if (!loadVia) {
            const requestOptions = {
                headers: authHeader()
            };
            if (this.props.bluePrint.entityFilter) {
                Axios.post(`${config.baseUrl}/api/admin/${entityAPI}/filter`, { query: this.props.bluePrint.entityFilter }, requestOptions)
                    .then(res => res.data)
                    .then(handleSuccess)
                    .catch(handleError);
            }
            else {
                Axios.get(`${config.baseUrl}/api/admin/${entityAPI}`, requestOptions)
                    .then(res => res.data)
                    .then(handleSuccess)
                    .catch(handleError);
            }
            let that = this;
            function handleSuccess(dataset) {
                that.setState({ dataset, datasetFiltered: dataset, fetching: false })
            }
            function handleError(err) {
                toast.error(`Unable to fetch ${entityAPI}`)
                that.setState({ dataset: -1, datasetFiltered: -1, fetching: false })
            }
        } else if (loadVia && loadViaId) {
            debugger;
            this.loadByParent(this.props.bluePrint)
        } else {
            this.setState({ fetching: false })
        }
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render

        let { loadVia, loadViaId } = nextProps.bluePrint;

        if ((loadViaId && loadViaId !== this.loadViaId && loadVia !== undefined)) {
            this.loadByParent(nextProps.bluePrint, true)
        }
    }

    loadByParent = (bluePrint, setNull = false) => {
        let { entityAPI, loadVia, loadViaId } = bluePrint;
        this.setState({ fetching: true })
        this.loadViaId = loadViaId;
        const requestOptions = {
            headers: authHeader()
        };
        Axios.post(`${config.baseUrl}/api/admin/${entityAPI}/filter`, { query: { [loadVia]: loadViaId } }, requestOptions).then(dataset => {
            if (setNull) this.handleChange({ target: { value: null } });
            this.setState({ dataset: dataset.data, datasetFiltered: dataset.data, bluePrint: bluePrint, fetching: false })

        }).catch(err => {
            toast.error(`Unable to fetch ${entityAPI}`)
            this.setState({ dataset: -1, datasetFiltered: -1, bluePrint: bluePrint, fetching: false })
        });
    }
    handleChange(value) {
        this.props.onChange(value);
        this.setState({ dropdownActive: false });
    }

    getSelectedValue = () => {
        let { value, bluePrint } = this.props;
        let { placeholder, displayfield } = bluePrint;
        let { dataset } = this.state;

        let retValue = placeholder;
        if (value) {
            let obj = dataset.find(m => m._id === value);
            if (obj) {
                return obj[displayfield];
            }
        }
        return retValue;
    }

    handleSearchChange = ({ target }) => {
        let { bluePrint } = this.props;
        let { displayfield } = bluePrint;
        let { dataset } = this.state;
        try {
            let ds = dataset.filter(m => m[displayfield].toLowerCase().includes(target.value.toLowerCase()));
            this.setState({ datasetFiltered: ds });
        } catch (error) {
            console.log({ error });
        }
    }

    render() {
        let { label, errors, disabled, placeholder, displayfield, entityAPI } = this.props.bluePrint;
        let { value, name, className } = this.props;

        let { dataset, fetching, datasetFiltered, dropdownActive } = this.state;
        return (
            <div className={`form-group ${className}`}>
                <ToastContainer />
                <label>{label}</label>
                <br />
                {fetching && <div className="text-primary">Fetching {entityAPI}..</div>}
                {/* {!fetching && dataset && dataset.length > 0 && <div>
                    <select
                        className="form-control"
                        name={name}
                        value={value}
                        disabled={disabled}
                        onChange={e => this.handleChange(e)}
                    >
                        <option value={null}>{placeholder}</option>
                        {dataset.map && dataset.map(d => <option value={d._id}>{d[displayfield]}</option>)}
                    </select>
                </div>
                } */}
                {
                    !fetching && dataset && dataset.length > 0 && <Fragment>
                        <div className="form-control parent-dropdown" onClick={() => this.setState({ dropdownActive: disabled ? false : !dropdownActive })}>
                            <span>{this.getSelectedValue()}</span>
                            <i className="fas fa-caret-down"></i>
                        </div>
                        {
                            dropdownActive && <div className="parent-dropdown-active">
                                <div className="parent-dropdown-active-search">
                                    <input className="form-control" placeholder="Search" onChange={this.handleSearchChange} />
                                </div>

                                <div className="parent-dropdown-active-container">
                                    {datasetFiltered.length > 0 ? datasetFiltered.map && datasetFiltered.map(d => <div
                                        onClick={() => this.handleChange(d._id)}
                                        className={`parent-dropdown-active-row ${d._id === value ? 'active' : ''}`}>
                                        {d[displayfield]}
                                    </div>)
                                        :
                                        <div className={`parent-dropdown-active-row`}>
                                            No Data Found!
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </Fragment>
                }
                {!fetching && ((dataset && dataset.length === 0) || !dataset) && <div className="text-info">No {entityAPI} found..</div>}
                {errors ? <div className="alert alert-danger">{errors[0]}</div> : null}
            </div>
        );
    }
}