import React from 'react';
import './style.css';
const SearchField = ({ label, value, onChange }) => {
    return (
        <div className="d-search-main-container">
            <div className="d-search-container">
                <input
                    className="d-search-field"
                    placeholder={`Search ${label}`} value={value} onChange={({ target }) => onChange && onChange(target.value)} />
                <button className="d-search-button"
                // onClick={() => onChange && onChange(value)}
                >
                    <i className="fa fa-search"></i>
                </button>
            </div>
        </div>
    );
};

export default SearchField;